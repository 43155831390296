import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useEnv } from '../../context/env-context';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useAuth } from '../../hooks/useAuth';
import { toast } from 'react-toastify';
import { makeRequest } from '../../utils/makeRequest';
import Loading from '../../components/Loading';
import { Spinner } from '../../components/Spinner';
import NewObjectLayout from './components/NewObjectLayout';
import { useClockContext } from '../../context/clock-context';

function NewObject() {
  const params = useParams();
  const navigate = useNavigate();

  const { getAccessTokenSilently } = useAuth();
  const {
    apiOriginConsumer,
    apiOriginAggregator,
    apiOriginFunding,
    apiOriginMonitor,
    apiOriginOrchestrator,
    apiOriginNotifications,
  } = useEnv();

  const { setConsumerLocation, setConsumerTimeZone } = useClockContext();

  const [consumer, setConsumer] = useState(null);
  const [aggregators, setAggregators] = useState([]);
  const [bridgePointsBalance, setBridgePointsBalance] = useState(null);
  const [openFunding, setOpenFunding] = useState(null);
  const [closedFundings, setClosedFundings] = useState(null);
  const [profileJson, setProfileJson] = useState(null);
  const [mobileDevice, setMobileDevice] = useState(null);

  const [jobId, setJobId] = useState(null);
  const [jobStatus, setJobStatus] = useState({
    finished: true,
    status: null,
  });

  const [loading, setLoading] = useState({
    bridgePointsBalance: true,
    profileJson: true,
    mobileDevice: true,
  });

  const [panelDataRefresh, setPanelDataRefresh] = useState({
    consumer: false,
    balances: false,
    profile: false,
  });

  useEffect(
    () => () => {
      setConsumerLocation({ city: null, state: null, country: 'US' });
      setConsumerTimeZone(null);
    },
    [setConsumerLocation, setConsumerTimeZone],
  );

  const getConsumer = useCallback(
    async id => {
      const token = await getAccessTokenSilently();

      if (!token) {
        return;
      }

      const config = {
        token,
        url: `${apiOriginConsumer}/get/${id}`,
        method: 'GET',
      };

      try {
        const response = await makeRequest(config);

        setConsumer(response);

        if (response && response.city && response.state) {
          setConsumerLocation(prev => ({ ...prev, city: response.city, state: response.state }));
        }
      } catch (error) {
        if (error.message === `Consumer ${id} not found!`) {
          navigate('/consumers');
          toast.error(`Consumer ID ${id} not found!`);
        } else {
          toast.error(error.message);
        }
      }
    },
    [getAccessTokenSilently, setConsumerLocation, apiOriginConsumer, navigate],
  );

  const getAggregators = useCallback(async () => {
    const token = await getAccessTokenSilently();

    if (!token) {
      return;
    }

    const config = {
      token,
      url: `${apiOriginAggregator}/get`,
      method: 'GET',
      params: {
        sortBy: 'name',
        sortOrder: 'asc',
        statusFilter: '',
        searchText: '',
        page: 0,
        perPage: 1000000,
      },
    };

    try {
      const response = await makeRequest(config);

      setAggregators(response);
    } catch (error) {
      toast.error(`Faled getting aggregators: ${error.message}`);
    }
  }, [apiOriginAggregator, getAccessTokenSilently]);

  const getBridgePointsBalance = useCallback(
    async consumerId => {
      const token = await getAccessTokenSilently();

      if (!token) {
        return;
      }

      setLoading(prev => ({ ...prev, bridgePointsBalance: true }));

      const config = {
        token,
        url: `${apiOriginConsumer}/bridgepoints/balance`,
        method: 'GET',
        params: { consumerId },
      };

      try {
        const response = await makeRequest(config);

        setBridgePointsBalance(response);
      } catch (error) {
        toast.error(error.message);
      } finally {
        setLoading(prev => ({ ...prev, bridgePointsBalance: false }));
      }
    },
    [apiOriginConsumer, getAccessTokenSilently],
  );

  const getOpenFunding = useCallback(
    async consumerId => {
      const token = await getAccessTokenSilently();

      if (!token) {
        return;
      }

      const config = {
        token,
        url: `${apiOriginFunding}/get_open_funding`,
        method: 'GET',
        params: { consumerId },
      };

      try {
        const data = await makeRequest(config);

        setOpenFunding(data);
      } catch (error) {
        toast.error(error.message);
      }
    },
    [apiOriginFunding, getAccessTokenSilently],
  );

  const getClosedFundings = useCallback(
    async consumerId => {
      const token = await getAccessTokenSilently();

      if (!token) {
        return;
      }

      const config = {
        token,
        url: `${apiOriginFunding}/get`,
        method: 'GET',
        params: {
          sortBy: 'createTimestamp',
          sortOrder: 'desc',
          consumerFilter: consumerId,
          page: 0,
          perPage: 4,
        },
      };

      try {
        const data = await makeRequest(config);

        setClosedFundings(
          data
            .filter(x => x.status !== 'OPEN' && x.status !== 'DUE' && x.status !== 'CLOSING')
            .slice(0, 3),
        );
      } catch (error) {
        toast.error(error.message);
      }
    },
    [apiOriginFunding, getAccessTokenSilently],
  );

  const getProfileJson = useCallback(
    async consumerId => {
      const token = await getAccessTokenSilently();

      if (!token) {
        return;
      }

      const config = {
        token,
        url: `${apiOriginMonitor}/get_profile_json`,
        method: 'GET',
        params: { consumerId },
      };

      setLoading(prev => ({ ...prev, profileJson: true }));

      try {
        const response = await makeRequest(config);

        setProfileJson(response);
      } catch (error) {
        console.error(error.message);
      } finally {
        setLoading(prev => ({ ...prev, profileJson: false }));
      }
    },
    [getAccessTokenSilently, apiOriginMonitor],
  );

  const getMobileDevices = useCallback(
    async consumerId => {
      const token = await getAccessTokenSilently();

      if (!token) {
        return null;
      }

      const config = {
        token,
        url: `${apiOriginNotifications}/get_multiple_devices`,
        method: 'GET',
        params: { consumerIds: consumerId },
      };

      setLoading(prev => ({ ...prev, mobileDevice: true }));

      try {
        const response = await makeRequest(config);

        setMobileDevice(response[consumerId].device);

        return response;
      } catch (error) {
        toast.error(error.message);

        return null;
      } finally {
        setLoading(prev => ({ ...prev, mobileDevice: false }));
      }
    },
    [apiOriginNotifications, getAccessTokenSilently],
  );

  const refreshConsumer = useCallback(
    async consumerId => {
      await getConsumer(consumerId);
      await getOpenFunding(consumerId);
      await getClosedFundings(consumerId);
      getBridgePointsBalance(consumerId);
      getProfileJson(consumerId);
      getMobileDevices(consumerId);
    },
    [
      getConsumer,
      getOpenFunding,
      getClosedFundings,
      getBridgePointsBalance,
      getProfileJson,
      getMobileDevices,
    ],
  );

  useEffect(() => {
    if (consumer) {
      setConsumer(null);
    }

    refreshConsumer(params.id);
    getAggregators();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id]);

  const getJobStatus = useCallback(
    async (id, consumerId) => {
      const token = await getAccessTokenSilently();

      if (!token) {
        return;
      }

      const config = {
        token,
        url: `${apiOriginOrchestrator}/job/get`,
        method: 'GET',
        params: { jobId: id },
      };

      try {
        const response = await makeRequest(config);

        if (!response.finished) {
          setTimeout(() => getJobStatus(id, consumerId), 3000);
          setJobStatus({ finished: response.finished, status: response.status });
        } else {
          await getProfileJson(consumerId);

          setJobStatus({ finished: response.finished, status: response.status });
          setJobId(null);
        }
      } catch (error) {
        toast.error(error.message);
      }
    },
    [apiOriginOrchestrator, getAccessTokenSilently, getProfileJson],
  );

  useEffect(() => {
    if (jobId !== null) {
      getJobStatus(jobId, params.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobId]);

  if (!consumer || !openFunding || !closedFundings) {
    return (
      <div className="mt-5">
        <Spinner visible />
      </div>
    );
  }

  return (
    <NewObjectLayout
      loading={loading}
      setJobId={setJobId}
      jobStatus={jobStatus}
      refreshConsumer={refreshConsumer}
      consumer={consumer}
      setConsumer={setConsumer}
      getConsumer={getConsumer}
      aggregators={aggregators}
      bridgePointsBalance={bridgePointsBalance}
      openFunding={openFunding}
      closedFundings={closedFundings}
      profileJson={profileJson}
      getProfileJson={getProfileJson}
      mobileDevice={mobileDevice}
      panelDataRefresh={panelDataRefresh}
      setPanelDataRefresh={setPanelDataRefresh}
    />
  );
}

export default withAuthenticationRequired(NewObject, {
  onRedirecting: () => <Loading />,
});
