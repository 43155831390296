import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { areEqual, VariableSizeGrid } from 'react-window';

const SCROLLBAR_SIZE = 10;

const HeaderCell = memo(
  ({ data: { tableColumns, headerTopBorder, addWidthToLastRow }, columnIndex, style }) => (
    <div
      key={columnIndex}
      style={{
        ...style,
        width: addWidthToLastRow ? style.width + SCROLLBAR_SIZE : style.width,
      }}
      className={`bo-table-cell fw-bold ps-3 pe-1 overflow-hidden border-bottom ${
        headerTopBorder ? 'border-top' : ''
      }`}
    >
      {Object.values(tableColumns)[columnIndex].header}
    </div>
  ),
  areEqual,
);

const Cell = memo(
  ({
    data: {
      tableRows,
      tableColumns,
      rowClickable,
      activeRowIndex,
      setActiveRowIndex,
      hoovering,
      hoveredRowIndex,
      setHoveredRowIndex,
      formatRowConditionally,
      handleRowRightClick,
    },
    columnIndex,
    rowIndex,
    style,
  }) => {
    let newStyle = { ...style };

    const { renderValue, key } = tableColumns[columnIndex];

    const value = tableRows[rowIndex][key];

    let className = `bo-table-cell ps-3 pe-1 overflow-hidden cell--hover${rowIndex}`;

    className += hoovering && hoveredRowIndex === rowIndex ? ' bo-table-hover-bg' : '';
    className += activeRowIndex === rowIndex ? ' bo-table-active-bg' : '';

    if (formatRowConditionally) {
      newStyle = { ...newStyle, ...formatRowConditionally(tableRows[rowIndex]) };
    }

    return (
      /* eslint-disable */
      <div
        key={columnIndex}
        style={newStyle}
        onClick={
          rowClickable
            ? () => setActiveRowIndex(prevIndex => (prevIndex === rowIndex ? null : rowIndex))
            : undefined
        }
        onMouseOver={hoovering ? () => setHoveredRowIndex(rowIndex) : undefined}
        onContextMenu={e => {
          if (handleRowRightClick) {
            handleRowRightClick(e, tableRows[rowIndex]);
          }
        }}
        className={className}
      >
        {renderValue ? renderValue(value, tableRows[rowIndex]) : value}
      </div>
      /* eslint-enable */
    );
  },
  areEqual,
);

function VirtualizedTable({
  tableRows,
  tableColumns,
  width,
  height,
  rowKey,
  rowHeight = 36,
  previewColumn,
  getRowHeight,
  headerHeight = 36,
  headerTopBorder,
  overscanColumnCount = 5,
  overscanRowCount = 10,
  hoovering = true,
  formatRowConditionally,
  handleRowRightClick,
}) {
  const [activeRowIndex, setActiveRowIndex] = useState(null);
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);

  const outerContainerRef = useRef(null);
  const headerGridRef = useRef(null);
  const mainGridRef = useRef(null);
  const mainGridContainerRef = useRef(null);

  const contentHeight = useMemo(
    () =>
      getRowHeight
        ? tableRows.reduce((acc, _, idx) => getRowHeight(idx) + acc, 0)
        : tableRows.length * rowHeight,
    [tableRows, getRowHeight, rowHeight],
  );
  const contentWidth = useMemo(
    () => tableColumns.reduce((acc, value) => acc + value.width, 0),
    [tableColumns],
  );

  const headerGridData = useMemo(
    () => ({
      tableColumns,
      headerTopBorder,
      addWidthToLastRow: contentHeight > height - headerHeight,
    }),
    [tableColumns, headerTopBorder, contentHeight, height, headerHeight],
  );

  const mainGridData = useMemo(
    () => ({
      tableRows,
      tableColumns,
      rowClickable: !!previewColumn,
      activeRowIndex,
      setActiveRowIndex,
      hoovering,
      hoveredRowIndex,
      setHoveredRowIndex,
      formatRowConditionally,
      handleRowRightClick,
    }),
    [
      tableRows,
      tableColumns,
      previewColumn,
      activeRowIndex,
      hoovering,
      hoveredRowIndex,
      formatRowConditionally,
      handleRowRightClick,
    ],
  );

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const { current: outerGrid } = outerContainerRef;

    if (outerGrid) {
      const handler = e => {
        e.preventDefault();
        const { deltaX, deltaY } = e;

        const { current: grid } = mainGridRef;
        const { current: header } = headerGridRef;
        const { current: gridDiv } = mainGridContainerRef;

        const hiddenContainer = gridDiv.firstElementChild;

        const maxVScroll = hiddenContainer.clientHeight - gridDiv.clientHeight;
        const maxHScroll = hiddenContainer.clientWidth - gridDiv.clientWidth;

        if (gridDiv && grid && header) {
          let { scrollLeft, scrollTop } = gridDiv;

          if (Math.abs(deltaY) > Math.abs(deltaX)) {
            scrollTop = scrollTop + deltaY < maxVScroll ? scrollTop + deltaY : maxVScroll;
          } else {
            scrollLeft = scrollLeft + deltaX < maxHScroll ? scrollLeft + deltaX : maxHScroll;
          }

          header.scrollTo({ scrollLeft });
          grid.scrollTo({ scrollLeft, scrollTop });
        }
      };

      outerGrid.addEventListener('wheel', handler);

      return () => outerGrid.removeEventListener('wheel', handler);
    }
  }, []);

  const onScroll = useCallback(({ scrollLeft, scrollUpdateWasRequested }) => {
    if (!scrollUpdateWasRequested && headerGridRef.current) {
      headerGridRef.current.scrollTo({ scrollLeft, scrollTop: 0 });
    }
  }, []);

  useEffect(() => {
    headerGridRef.current?.resetAfterColumnIndex(tableColumns.length - 1);
    mainGridRef.current?.resetAfterColumnIndex(tableColumns.length - 1);
  }, [width, height, tableColumns, activeRowIndex]);

  useEffect(() => {
    mainGridRef.current?.resetAfterRowIndex(0);
  }, [tableRows]);

  const getColumnWidth = useCallback(
    index => {
      if (
        index === tableColumns.length - 1 &&
        width >
          (activeRowIndex !== null && !!previewColumn
            ? contentWidth + previewColumn.width
            : contentWidth)
      ) {
        return (
          width -
          (activeRowIndex !== null && !!previewColumn ? previewColumn.width : 0) -
          contentWidth -
          (contentHeight + headerHeight > height ? SCROLLBAR_SIZE : 0) +
          tableColumns[tableColumns.length - 1].width
        );
      }

      return tableColumns[index].width;
    },
    [
      width,
      tableColumns,
      activeRowIndex,
      previewColumn,
      contentWidth,
      contentHeight,
      height,
      headerHeight,
    ],
  );

  const estimatedColumnWidth = useMemo(
    () => Math.round(contentWidth / Object.values(tableColumns).length),
    [contentWidth, tableColumns],
  );

  const estimatedRowHeight = useMemo(
    () => Math.round(contentHeight / tableRows.length),
    [contentHeight, tableRows],
  );

  return (
    <div className="d-flex">
      <div ref={outerContainerRef} onMouseLeave={() => setHoveredRowIndex(null)}>
        <VariableSizeGrid
          itemData={headerGridData}
          ref={headerGridRef}
          className="bo-table-header-container"
          columnCount={Object.keys(tableColumns).length}
          columnWidth={index => getColumnWidth(index)}
          height={headerHeight}
          rowCount={1}
          rowHeight={() => headerHeight}
          width={activeRowIndex !== null && !!previewColumn ? width - previewColumn.width : width}
          estimatedColumnWidth={estimatedColumnWidth}
          estimatedRowHeight={headerHeight}
          overscanColumnCount={overscanColumnCount}
        >
          {HeaderCell}
        </VariableSizeGrid>
        <VariableSizeGrid
          itemKey={({ columnIndex, data, rowIndex }) =>
            `${data.tableRows[rowIndex][rowKey]}-${columnIndex}`
          }
          className="bo-table-container"
          ref={mainGridRef}
          outerRef={mainGridContainerRef}
          itemData={mainGridData}
          onScroll={onScroll}
          columnCount={tableColumns.length}
          columnWidth={index => getColumnWidth(index)}
          height={height - headerHeight}
          rowCount={tableRows.length}
          rowHeight={getRowHeight || (() => rowHeight)}
          width={activeRowIndex !== null && !!previewColumn ? width - previewColumn.width : width}
          estimatedColumnWidth={estimatedColumnWidth}
          estimatedRowHeight={estimatedRowHeight}
          overscanColumnCount={overscanColumnCount}
          overscanRowCount={overscanRowCount}
        >
          {Cell}
        </VariableSizeGrid>
      </div>
      {activeRowIndex !== null && !!previewColumn && (
        <div style={{ width: previewColumn.width, height }}>
          <div
            style={{
              width: previewColumn.width,
              height: headerHeight,
            }}
            className={`bo-table-cell fw-bold px-1 overflow-hidden border-bottom border-start ${
              headerTopBorder ? 'border-top' : ''
            }`}
          >
            {previewColumn.header}
          </div>
          <div
            style={{ width: previewColumn.width, height: height - headerHeight }}
            // eslint-disable-next-line max-len
            className="bo-table-cell bo-table-container align-items-start overflow-auto border-start"
          >
            {previewColumn.renderValue
              ? previewColumn.renderValue(
                  tableRows[activeRowIndex][previewColumn.key],
                  tableRows[activeRowIndex],
                  height - headerHeight,
                )
              : tableRows[activeRowIndex][previewColumn.key]}
          </div>
        </div>
      )}
    </div>
  );
}

export default VirtualizedTable;
