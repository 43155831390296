export const REPORTS_DISPLAY_NAMES = {
  REPAYMENTS_ANALYTICS: 'Repayment Analytics',
  BILLING: 'Billing',
  CONSUMER_ECONOMICS: 'Consumer Economics',
  CONSUMER_ANALYTICS: 'Consumer Analytics',
  CONSUMER_HUBSPOT: ' Consumer HubSpot',
  ADVANCES_ANALYTICS: 'Advances Analytics',
  TRANSFERS_ANALYTICS: 'Transfers Analytics',
  SIGNUP_FUNNEL: 'Signup Funnel',
  GENERIC_SIGNUP: 'Generic Signup',
  RISK_INDICATORS: 'Risk Indicators',
  DETAILED_SIGNUP_FUNNEL: 'Detailed Signup Funnel',
  REFERRALS: 'Referrals',
};

export const REPORTS_STATUS_BADGE = {
  PENDING: {
    text: 'Pending',
    color: 'warning',
  },
  RUNNING: {
    text: 'Running',
    color: 'info',
  },
  READY: {
    text: 'Ready',
    color: 'success',
  },
  FAILED: {
    text: 'Failed',
    color: 'danger',
  },
};
