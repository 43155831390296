import { useState } from 'react';
import { Button } from 'reactstrap';
import { faAngleDown, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function NewObjectAccordion({ title, bottomBorder, renderRightPart, children }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className={`bo-new-object-accordion ${
        bottomBorder ? 'bo-new-object-accordion-bottom-border' : ''
      }`}
    >
      <Button className="bo-new-object-accordion-top-section" onClick={() => setIsOpen(!isOpen)}>
        <div className="bo-new-object-accordion-icon">
          <FontAwesomeIcon icon={isOpen ? faAngleDown : faAngleRight} size="sm" color="black" />
        </div>
        <p className="bo-new-object-text-bold">{title}</p>
        {renderRightPart && renderRightPart()}
      </Button>
      {isOpen && <div className="bo-new-object-accordion-content">{children}</div>}
    </div>
  );
}

export default NewObjectAccordion;
